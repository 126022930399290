import type * as CSS from "csstype";

export const FONT = "Fira Sans Extra Condensed,sans-serif";

export const COLORS = {
  lightBackground: "#2a2a33",
  buttonBackground: "#383845",
  arrow: "#fff",
  lightGrayText: "#6a6a7c",
  button: "#663df3",
  border: "#292b33",
  primaryHue: "hsl(204, 100%, 50%)",
};

export const button: CSS.Properties = {
  borderRadius: "29px",
  background: COLORS.button,
  boxShadow: "inset 3px 3px rgb(114, 74, 244), 0 0 2px rgb(0, 0, 0)",
  border: "0",
  minHeight: "39px",
  fontFamily: FONT,
  lineHeight: "35px",
  fontSize: "16px",
  color: "white",
  cursor: "pointer",
};

export const selector: CSS.Properties = {
  appearance: "none",
  backgroundColor: COLORS.lightBackground,
  color: "white",
  padding: "10px",
  border: "0",
  minHeight: "40px",
  borderRadius: "3px",
  backgroundImage: "url(/dropdown.png)",
  backgroundPosition: "right",
  backgroundSize: "contain",
  backgroundRepeat: "no-repeat",
  fontFamily: FONT,
  fontSize: "15px",
  width: "100%",
};
