import React from "react";
import { DocsThemeConfig } from "nextra-theme-docs";
import { useRouter } from "next/router";
import { match, P } from "ts-pattern";
import { strings } from "./src/calc/i18nStrings";

const config: DocsThemeConfig = {
  project: {},
  chat: {},
  footer: { component: undefined },
  sidebar: {
    toggleButton: true,
  },
  nextThemes: { forcedTheme: "dark" },
  // primaryHue: { dark: 4, light: 4 },
  darkMode: true,
  editLink: { component: undefined },
  feedback: { content: undefined },
  i18n: [
    { locale: "en", text: "English" },
    { locale: "ua", text: "Ukrainian (українська)" },
    { locale: "ru", text: "Russian (русский)" },
  ],
  head: (
    <>
      <script
        async
        src="https://www.googletagmanager.com/gtag/js?id=G-7RT4L8C5YF"
      ></script>
      <script
        dangerouslySetInnerHTML={{
          __html: `
window.dataLayer = window.dataLayer || [];
function gtag(){dataLayer.push(arguments);}
gtag('js', new Date());

gtag('config', 'G-7RT4L8C5YF');
`,
        }}
      ></script>
      <script
        async
        src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-1374017065120638"
        crossOrigin="anonymous"
      />
      <link
        href="https://fonts.googleapis.com/css?family=Fira+Sans+Extra+Condensed"
        rel="stylesheet"
      />
      <link rel="icon" href="/favicon.ico" type="image/x-icon" />
    </>
  ),
  logo: () => {
    const router = useRouter();

    const locale = router.locale;

    const title = match(locale)
      .with("en", () => "String Tension Calculator")
      .with("ru", () => "Калькулятор Натяжения Струн")
      .with("ua", () => "Калькулятор Натягу Струн")
      .with(P.any, () => "String Tension Calculator")
      .exhaustive();

    return (
      <>
        <img
          src={"/logo_header.png"}
          style={{ width: "35px", height: "42px" }}
        />
        <h1
          style={{
            marginLeft: "20px",
            fontFamily: "Fira Sans Extra Condensed,sans-serif",
            fontSize: "24px",
            fontWeight: "bold",
            lineHeight: "28px",
          }}
        >
          {title}
        </h1>
      </>
    );
  },
  themeSwitch: { component: null },
  search: { component: undefined },
  // search: { placeholder: "Search..." },
  navigation: { prev: false, next: false },
  // banner: { text: "MEOW" }, // support ukraine?
  docsRepositoryBase: "https://github.com/aaronabramov/string-calc",
  useNextSeoProps: () => {
    const { route, locale } = useRouter();

    // deduplication of all `q=sontheusnathoesu` urls to avoid
    // over-indexing
    const canonical = match(route)
      .with("/index.en", () => "https://www.stringtensioncalculator.com")
      .with("/index.ru", () => "https://www.stringtensioncalculator.com/ru/")
      .with("/index.ua", () => "https://www.stringtensioncalculator.com/ua/")
      .with(P.any, () => undefined)
      .exhaustive();

    const ogImage = match(locale)
      .with("ru", () => "/og-ru.jpeg")
      .with("ua", () => "/og-ua.jpeg")
      .with("en", () => "/og-en.jpeg")
      .with(P.any, () => "/og-en.jpeg")
      .exhaustive();

    const localizedStrings = match(locale)
      .with("ua", () => strings.ua)
      .with("ru", () => strings.ru)
      .with("en", () => strings.en)
      .with(P.any, () => strings.en)
      .exhaustive();

    return {
      canonical,
      titleTemplate: "%s",
      description: localizedStrings.Description,
      openGraph: {
        images: [
          {
            url: ogImage,
            width: 2424,
            height: 1269,
            alt: localizedStrings.Title,
            type: "image/jpeg",
          },
        ],
      },
    };
  },
};

export default config;
