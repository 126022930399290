import dynamic from "next/dynamic";
import { Shimmer } from "./ui/Shimmer";

export default dynamic(() => import("./Calc"), {
  loading: () => <Shimmer />,
  ssr: false,
});

export const Sample = dynamic(() => import("./Sample"), {
  loading: () => <Shimmer />,
  ssr: false,
});
