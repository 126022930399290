import { setupNextraPage } from 'nextra/setup-page'
import __nextra_layout from 'nextra-theme-docs'
import __nextra_themeConfig from '/vercel/path0/theme.config.tsx'

import 'nextra-theme-docs/style.css'

const __nextraPageOptions = {
  MDXContent,
  pageOpts: {"filePath":"pages/index.en.mdx","route":"/","frontMatter":{"title":"String Tension Calculator"},"headings":[],"timestamp":1684445074000,"pageMap":[{"kind":"Meta","locale":"en","data":{"index":{"title":"String Tension Calculator","theme":{"breadcrumb":false,"sidebar":true}},"what-is-guitar-string-tension":{"title":"What is guitar string tension?","theme":{"breadcrumb":false,"sidebar":true}},"contact":{"title":"Contact ↗","type":"page","href":"https://x.com/boujeepossum","newWindow":true}}},{"kind":"MdxPage","name":"index","route":"/","locale":"en","frontMatter":{"title":"String Tension Calculator"}},{"kind":"MdxPage","name":"what-is-guitar-string-tension","route":"/what-is-guitar-string-tension","locale":"en"}],"flexsearch":{"codeblocks":true},"title":"String Tension Calculator"},
  pageNextRoute: "/index.en",
  nextraLayout: __nextra_layout,themeConfig: __nextra_themeConfig
}
/*@jsxRuntime automatic @jsxImportSource react*/
import {useMDXComponents as _provideComponents} from "nextra/mdx";
import $NextImageNextra from "next/image";
import Calc from "../src/calc";
function _createMdxContent(props) {
  return <><Calc config={{
    syncUrl: true
  }} />{"\n"}<span />{"\n"}<span /></>;
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? <MDXLayout {...props}><_createMdxContent {...props} /></MDXLayout> : _createMdxContent(props);
}


if (process.env.NODE_ENV !== 'production') {
  __nextraPageOptions.hot = module.hot
  __nextraPageOptions.pageOptsChecksum = ''
}
if (typeof window === 'undefined') __nextraPageOptions.dynamicMetaModules = []

export default setupNextraPage(__nextraPageOptions)