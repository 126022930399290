import React from "react";
import type * as CSS from "csstype";
import { COLORS } from "./styles";

const styles: { [key: string]: CSS.Properties } = {
  spinner: {
    width: "40px",
    height: "40px",
    border: "5px solid rgba(0, 0, 0, 0.1)",
    borderTopColor: COLORS.button,
    borderRadius: "50%",
    animation: "spin 1s linear infinite",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100px",
    padding: "20px",
  },
};

export const Shimmer = () => {
  const spinnerStyle = {
    width: "40px",
    height: "40px",
    border: "5px solid rgba(0, 0, 0, 0.1)",
    borderTopColor: COLORS.button,
    borderRadius: "50%",
    animation: "spin 1s linear infinite",
  };

  const keyframes = `
        @keyframes spin {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }
      `;

  return (
    <div style={styles.container}>
      <div style={spinnerStyle} />
      <style>{keyframes}</style>
    </div>
  );
};
