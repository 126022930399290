
export type Locale = "en" | "ru" | "ua";

export type Strings = {
  Title: string,
  Description: string,
  Scale: string,
  Note: string,
  Gauge: string,
  Tension: string,
  Frequency: string,
  Guitar: string,
  Bass: string,
  CopyLink: string,
  Copied: string,
  SingleScale: string,
  Multiscale: string,
  SelectYourScale: string,
  AddString: string,
  "HelpBox.StringTension": string,
  "HelpBox.ColorOfTension": string,
  "HelpBox.TypicallyForGuitars": string,
  "HelpBox.TypicallyForBasses": string,
  "HelpBox.LightTension": string,
  "HelpBox.RegularTension": string,
  "HelpBox.HeavyTension": string,
  "HelpBox.ValuesMayVary": string,
  "HelpBox.Lighter": string,
  "HelpBox.Heavier": string,
}

export const strings: { [key in Locale]: Strings } = {
  'en': {
    Title: 'String Tension Calculator',
    Description: "Find the right string gauge for your guitar or bass to achieve the perfect tension.",
    Scale: 'Scale',
    Note: 'Note',
    Gauge: 'Gauge',
    Tension: 'Tension',
    Frequency: 'Frequency',
    Guitar: 'Guitar',
    Bass: 'Bass',
    CopyLink: 'Copy Link',
    Copied: 'Copied!',
    SingleScale: 'Single Scale',
    Multiscale: 'Multiscale',
    SelectYourScale: 'Select Your Scale',
    AddString: 'Add String',
    "HelpBox.StringTension": 'String Tension',
    "HelpBox.ColorOfTension": `The color of the tension value in the table shows how light (or heavy)
    the string is, with "yellow" being lighter and "red" being heavier.`,
    "HelpBox.TypicallyForGuitars": `Typically for guitars:`,
    "HelpBox.TypicallyForBasses": `And for basses`,
    "HelpBox.LightTension": `light tension`,
    "HelpBox.RegularTension": `regular tension`,
    "HelpBox.HeavyTension": `heavy tension`,
    "HelpBox.ValuesMayVary": `Although these values may vary depending on the instrument technical
    characteristics.`,
    "HelpBox.Lighter": 'LIGHTER',
    "HelpBox.Heavier": 'HEAVIER',

  },
  'ua': {
    Title: 'Калькулятор натягу струн',
    Description: "Знайдіть правильний калібр струн для своєї гітари або бас-гітари для досягнення ідеального натягу.",
    Scale: 'Мензура',
    Note: 'Нота',
    Gauge: 'Калібр',
    Tension: 'Натяг',
    Frequency: 'Частота',
    Guitar: 'Гітара',
    Bass: 'Бас',
    CopyLink: 'Скопіювати посилання',
    Copied: 'Скопійовано!',
    SingleScale: 'Звичайна мензура',
    Multiscale: 'Мультимензура',
    SelectYourScale: 'Виберіть мензуру',
    AddString: 'Додати струну',
    "HelpBox.StringTension": 'Натяг струн',
    "HelpBox.ColorOfTension": `Колір цифри натягу струни в таблиці показує наскільки сильно натягнута струна. Жовтий колір означає, що струна натягнута слабо, червоний — сильно.`,
    "HelpBox.TypicallyForGuitars": `Звичайні значення для гітар:`,
    "HelpBox.TypicallyForBasses": `Tа для басів`,
    "HelpBox.LightTension": `легкий натяг`,
    "HelpBox.RegularTension": `звичайний натяг`,
    "HelpBox.HeavyTension": `сильний натяг`,
    "HelpBox.ValuesMayVary": `Дані значення можуть помітно відрізнятися залежно від інструменту та його технічних характеристик`,
    "HelpBox.Lighter": 'ЛЕГШЕ',
    "HelpBox.Heavier": 'СИЛЬНІШЕ',
  },
  'ru': {
    Title: 'Калькулятор натяжения струн',
    Description: 'Найдите правильный калибр струн для своей гитары или бас-гитары, чтобы достичь идеального натяжения.',
    Scale: 'Мензура',
    Note: 'Нота',
    Gauge: 'Калибр',
    Tension: 'Натяжение',
    Frequency: 'Частота',
    Guitar: 'Гитара',
    Bass: 'Бас',
    CopyLink: 'Скопировать ссылку',
    Copied: 'Скопировано!',
    SingleScale: 'Обычная мензура',
    Multiscale: 'Мультимензура',
    SelectYourScale: 'Выберите мензуру',
    AddString: 'Добавить струну',
    "HelpBox.StringTension": 'Натяжение струн',
    "HelpBox.ColorOfTension": `Цвет цифры натяжение струны в таблице показывает как сильно струна натянута. Желтый цвет означает что струна натянута слабо, а красный что сильно`,
    "HelpBox.TypicallyForGuitars": `Обычные значения для гитар:`,
    "HelpBox.TypicallyForBasses": `Для басов`,
    "HelpBox.LightTension": `легкое натяжение`,
    "HelpBox.RegularTension": `обычное натяжение`,
    "HelpBox.HeavyTension": `сильное натяжение`,
    "HelpBox.ValuesMayVary": `Данные значение могут заметно оличатся в зависимости от инструмента и его технических характеристик`,
    "HelpBox.Lighter": 'ЛЕГЧЕ',
    "HelpBox.Heavier": 'СИЛЬНЕЕ',
  }
}
